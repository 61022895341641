import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Nyoko from '../NYOKO.png'

// ... (your imports here)

export default function ButtonAppBar({marginleft,margintop,alignment}) {
  const theme = useTheme();
  // This will be true if the screen width is less than 'sm' breakpoint
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    // <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={0} sx={{ backgroundColor: 'inherit', color: 'inherit' }}>
        <Toolbar
            sx={{ 
              display: 'flex', 
              backgroundColor: 'inherit', 
              color: 'inherit', 
              marginLeft: isMobile ? marginleft : '85px', // Here we use the isMobile flag for conditional styling
              marginTop: isMobile ? '20px' : '30px', // You can apply the same for marginTop if needed
              alignItems: alignment,
              justifyContent: alignment,
              ...(isMobile && { padding: 0 }),
            }}
        >
          <img 
          src={Nyoko} 
          alt="Logo" 
          style={{ 
            height: isMobile ? '20px' : '20px', // Adjust size as needed
            
          }} 
        />
          {/* <Typography variant="h6" sx={{ mt: isMobile ? 0 : 'auto', mb: isMobile ? 0 : 'auto',flexGrow: alignment === 'center' ? 0 : 1,  fontSize: 25, fontWeight: 700, letterSpacing: 2, color: '#676767' }}>
            NYOKO
          </Typography> */}
        </Toolbar>
      </AppBar>
    // </Box>
  );
}
