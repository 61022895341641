import React , {useState} from 'react';
import NavbarNyoko from '../Components/navbar';
import SecondSec from '../Components/secondSec';
import ExplorePhotographers from '../Components/text';
import Grid from '@mui/material/Grid';
import formimage from '../formimage.png'
import EmailInput from '../Components/emailinput';
import MultipleSelect from '../Components/selectinput';
import BasicTextFields from '../Components/simpleinput';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import ContainedButtons from '../Components/button';
import BottomAppBar from '../Components/bottombar';


function Form() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject,setSubject] = useState('');
  const theme = useTheme();
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const validate = () => {
    let isValid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex

    if (!name) {
      setNameError('Full Name is required');
      isValid = false;
    } else {
      setNameError('');
    }
  
    if (!email) {
      setEmailError('Email Address is required');
      isValid = false;
    }else if (!emailRegex.test(email)) {
      setEmailError('Invalid email format');
      isValid = false;
    } else {
      setEmailError('');
    }
  
    if (!subject) {
      setSubjectError('Please select an option');
      isValid = false;
    } else {
      setSubjectError('');
    }
  
    return isValid;
  };
  const handleSubmit = async () => {
    if (!validate()) return false;
    const payload = {
      name: name,
      email: email,
      subject: subject,
      message: 'Just checking for now'
    };
   
    try {
      const response = await fetch('https://api.nyoko.com/api/v1/contact_us/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      });
  
      if (response.ok) {
        // Handle success
        console.log("Data sent successfully");
        return true;
      } else {
        // Handle errors
        console.error("Error in sending data");
        return false;
      }
    } catch (error) {
      console.error("Error in sending data", error);
      return false;
    }
  }
  // This will be true if the screen width is less than 'sm' breakpoint
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  

  return (
    <div  style={{
        padding: '0',
        overflowX: 'hidden',
        backgroundColor: '#201F1F',
        color: 'white',
        // height: '100vh',
        fontFamily: 'lato,sans-serif',
        width: '100vw',
        overflow:'hidden'
     
      }}
    >
    
    <div style={{ flexDirection: 'column' }}>
  {/* Conditionally render the NavbarNyoko based on screen size */}
  {isMobile && (
    <div style={{ flex: 1 }}>
      <NavbarNyoko marginleft={isMobile?'29px':'0px'}/>
    </div>
  )}
  

  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
    {/* Left Section - Now with image */}
    <div style={{ flex: isMobile ? 0 : 1, display: isMobile ? 'none' : 'block' }}>
      <SecondSec  imgSrc={formimage} altText="Banner" includeMargin={true} margintop='30px' height='90vh'/>
    </div>

    {/* Right Section - Now with text */}
    <div style={{ flex: 1 }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1, marginLeft: isMobile ? '10px' : -9 }}>
          <ExplorePhotographers parawidth='300px' title={isMobile?'Future of photography is here.': 'Explore the future of photography.'} paragraph={"An on demand photography platform that connects talented photographers to those looking to create lasting memories through photoshoots."} marginleft='-8px' marginright='0px' headingfont='50px' paragraphfont={isMobile? '16px':'20px'}/>
        </div>

        <div style={{ flex: 1, marginLeft: isMobile ? '10px' : '-20px' }}>
          <BasicTextFields
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Full Name"
            error={!!nameError}
            helperText={nameError}
          />
        </div>
        <div style={{ flex: 1, marginLeft: isMobile ? '10px' : '-20px'  }}>
          <EmailInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email Address"
            error={!!emailError}
            helperText={emailError}
          />
        </div>
        <div style={{ flex: 1, marginLeft: isMobile ? '10px' : '-20px'  }}>
          <MultipleSelect
            value={subject}
            onChange={(selectedValue) => setSubject(selectedValue)}
            error={!!subjectError}
            helperText={subjectError}
          />
        </div>
        <div style={{ flex: 1, marginTop: '42px', marginLeft: isMobile ? '10px' : '-20px'}}>
          <ContainedButtons outline='#2F96A9' onClick={handleSubmit} includeMargin={isMobile? '21px':'3px' } navigateTo="/registered" bgcolor='#253A40'  text="Continue" alignment='flex-start' textcolor='#EEEEEE' width={isMobile ? '87%': '69%'} formSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  </div>
</div>
      
      {/* <BottomAppBar  alignment='center'></BottomAppBar> */}

    </div>
  );
}

export default Form;
