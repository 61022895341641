import React from "react";

const Logo = ({ fontSize }) => {
  const size = fontSize || "text-2xl";
  return (
    <div
      className={`text-2xl uppercase font-bold text-[#676767] font-sans ${size}`}
    >
      <h1 style={{ letterSpacing: "4px" }}>Nyoko</h1>
    </div>
  );
};

export default Logo;
