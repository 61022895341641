import { Box } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

function SecondSec({ imgSrc, altText, includeMargin, height, margintop }) {
  const marginRightValue = includeMargin ? '25%' : '0%';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        height: height,
        alignItems: 'center',
        justifyContent: isMobile ? 'center' : 'flex-start',
        backgroundColor: 'transparent',
        paddingLeft: '0',
        marginTop: margintop,
        marginLeft: marginRightValue,
        marginBottom: '0px',
      }}
    >
      <img src={imgSrc} alt={altText} style={{ height: '100%' }} />
    </Box>
  );
}

export default SecondSec;
