import React from 'react';

const ButtonSubmit = ({label, onClick}) => {
    return (
        <button onClick={onClick} className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-3 px-4 rounded-sm">
            {label}
        </button>
    );
};

export default ButtonSubmit;