import React from "react";

function PrivacyPolicy() {
  return (
    <div
      style={{
        padding: "5vw",
        backgroundColor: "#000",
        color: "white",
        fontFamily: "Lato",
      }}
    >      <>
        <div className="previewContent" style={
          {
            "fontFamily": "Helvetica, Arial, sans-serif",
            "fontSize": "14px",
            "color": "#ffffff",
            "lineHeight": "1.5",
            "padding": "0px",
            "margin": "0px",
            "boxSizing": "border-box",
            "minWidth": "0px",
            "minHeight": "0px",
            "maxWidth": "100%",
            "maxHeight": "100%",
            "overflow": "auto",
            "wordWrap": "break-word",
            "wordBreak": "break-word",
            "hyphens": "auto",
            "textAlign": "start",
            "textAlignLast": "auto",
            "textJustify": "auto",
            "textIndent": "0px",
            "textTransform": "none",
            "textShadow": "none",
            "textDecoration": "none solid rgb(255, 255, 255)",
            "textDecorationSkipInk": "auto",
            "textDecorationStyle": "solid",
          }
        }
        >
          <article>
            <div data-custom-class="body">
              <div>
                <strong>
                  <span style={{ fontSize: 30 }}>
                    <span data-custom-class="title">
                      <bdt className="block-component" />
                      <bdt className="question">PRIVACY POLICY</bdt>
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </strong>
              </div>
              <div>
                <br />
              </div>
              <div>
                <span>
                  <strong>
                    <span>
                      <span >
                        Last updated{" "}
                        <bdt className="question">April 01, 2023</bdt>
                      </span>
                    </span>
                  </strong>
                </span>
              </div>
              <div>
                <br />
              </div>
              <div>
                <br />
              </div>
              <div>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      This privacy notice for{" "}
                      <bdt className="question">
                        Nyoko Inc
                        <bdt className="block-component" /> (doing business as{" "}
                        <bdt className="question">Nyoko</bdt>)
                        <bdt className="statement-end-if-in-editor" />
                      </bdt>{" "}
                      (<bdt className="block-component" />"
                      <bdt className="block-component" />
                      <bdt className="question">
                        <strong>Nyoko</strong>
                      </bdt>
                      <bdt className="else-block" />
                      ," "<strong>we</strong>," "<strong>us</strong>," or "
                      <strong>our</strong>"
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                    <span data-custom-class="body_text">
                      ), describes how and why we might collect, store, use,
                      and/or share (<bdt className="block-component" />"
                      <strong>process</strong>"
                      <bdt className="statement-end-if-in-editor" />) your
                      information when you use our services (
                      <bdt className="block-component" />"
                      <strong>Services</strong>"
                      <bdt className="statement-end-if-in-editor" />
                      ), such as when you:
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <span >
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        Visit our website
                        <bdt className="block-component" /> at{" "}
                        <bdt className="question">
                          <a
                            href="https://nyoko.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            data-custom-class="link"
                          >
                            https://nyoko.com
                          </a>
                        </bdt>
                        <span>
                          <span >
                            <span data-custom-class="body_text">
                              <span>
                                <span >
                                  <bdt className="statement-end-if-in-editor">
                                    , or any website of ours that links to this
                                    privacy notice
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div>
                <bdt className="block-component">
                  <span>
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          <span >
                            <span data-custom-class="body_text">
                              <bdt className="block-component" />
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </bdt>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        Download and use
                        <bdt className="block-component" /> our mobile
                        application
                        <bdt className="block-component" /> (
                        <bdt className="question">
                          Nyoko)
                          <span
                          
                          >
                            <span
                            
                            >
                              <span data-custom-class="body_text">
                                <span>
                                  <span >
                                    <span data-custom-class="body_text">
                                      <span>
                                        <span
                                          
                                        >
                                          <bdt className="statement-end-if-in-editor">
                                            ,
                                          </bdt>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </bdt>
                      </span>
                      <span data-custom-class="body_text">
                        <span>
                          <span >
                            <span data-custom-class="body_text">
                              <span>
                                <span >
                                  <bdt className="statement-end-if-in-editor">
                                    <bdt className="block-component">
                                      {" "}
                                      or any other application of ours that
                                      links to this privacy notice
                                    </bdt>
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <span >
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        Engage with us in other related ways, including any
                        sales, marketing, or events
                        <span>
                          <span >
                            <span data-custom-class="body_text">
                              <span>
                                <span >
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <strong>Questions or concerns?&nbsp;</strong>Reading this
                      privacy notice will help you understand your privacy
                      rights and choices. If you do not agree with our policies
                      and practices, please do not use our Services. If you
                      still have any questions or concerns, please contact us at{" "}
                      <bdt className="question">contact@nyoko.com</bdt>.
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <strong>
                  <span>
                    <span data-custom-class="heading_1">
                      SUMMARY OF KEY POINTS
                    </span>
                  </span>
                </strong>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span data-custom-class="body_text">
                    <strong>
                      <em>
                        This summary provides key points from our privacy
                        notice, but you can find out more details about any of
                        these topics by clicking the link following each key
                        point or by using our&nbsp;
                      </em>
                    </strong>
                  </span>
                </span>
                <a data-custom-class="link" href="#toc">
                  <span>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>table of contents</em>
                      </strong>
                    </span>
                  </span>
                </a>
                <span>
                  <span data-custom-class="body_text">
                    <strong>
                      <em>
                        &nbsp;below to find the section you are looking for.
                      </em>
                    </strong>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span data-custom-class="body_text">
                    <strong>What personal information do we process?</strong>{" "}
                    When you visit, use, or navigate our Services, we may
                    process personal information depending on how you interact
                    with <bdt className="block-component" />
                    <bdt className="question">Nyoko</bdt>
                    <bdt className="else-block" /> and the Services, the choices
                    you make, and the products and features you use. Learn more
                    about&nbsp;
                  </span>
                </span>
                <a data-custom-class="link" href="#personalinfo">
                  <span>
                    <span data-custom-class="body_text">
                      personal information you disclose to us
                    </span>
                  </span>
                </a>
                <span data-custom-class="body_text">.</span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span data-custom-class="body_text">
                    <strong>
                      Do we process any sensitive personal information?
                    </strong>{" "}
                    <bdt className="block-component" />
                    We do not process sensitive personal information.
                    <bdt className="else-block" />
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span data-custom-class="body_text">
                    <strong>
                      Do we receive any information from third parties?
                    </strong>{" "}
                    <bdt className="block-component" />
                    We do not receive any information from third parties.
                    <bdt className="else-block" />
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span data-custom-class="body_text">
                    <strong>How do we process your information?</strong> We
                    process your information to provide, improve, and administer
                    our Services, communicate with you, for security and fraud
                    prevention, and to comply with law. We may also process your
                    information for other purposes with your consent. We process
                    your information only when we have a valid legal reason to
                    do so. Learn more about&nbsp;
                  </span>
                </span>
                <a data-custom-class="link" href="#infouse">
                  <span>
                    <span data-custom-class="body_text">
                      how we process your information
                    </span>
                  </span>
                </a>
                <span data-custom-class="body_text">.</span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span data-custom-class="body_text">
                    <strong>
                      In what situations and with which{" "}
                      <bdt className="block-component" />
                      parties do we share personal information?
                    </strong>{" "}
                    We may share information in specific situations and with
                    specific <bdt className="block-component" />
                    third parties. Learn more about&nbsp;
                  </span>
                </span>
                <a data-custom-class="link" href="#whoshare">
                  <span>
                    <span data-custom-class="body_text">
                      when and with whom we share your personal information
                    </span>
                  </span>
                </a>
                <span>
                  <span data-custom-class="body_text">
                    .<bdt className="block-component" />
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span data-custom-class="body_text">
                    <strong>How do we keep your information safe?</strong> We
                    have <bdt className="block-component" />
                    organizational
                    <bdt className="statement-end-if-in-editor" /> and technical
                    processes and procedures in place to protect your personal
                    information. However, no electronic transmission over the
                    internet or information storage technology can be guaranteed
                    to be 100% secure, so we cannot promise or guarantee that
                    hackers, cybercriminals, or other{" "}
                    <bdt className="block-component" />
                    unauthorized
                    <bdt className="statement-end-if-in-editor" /> third parties
                    will not be able to defeat our security and improperly
                    collect, access, steal, or modify your information. Learn
                    more about&nbsp;
                  </span>
                </span>
                <a data-custom-class="link" href="#infosafe">
                  <span>
                    <span data-custom-class="body_text">
                      how we keep your information safe
                    </span>
                  </span>
                </a>
                <span data-custom-class="body_text">.</span>
                <span>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span data-custom-class="body_text">
                    <strong>What are your rights?</strong> Depending on where
                    you are located geographically, the applicable privacy law
                    may mean you have certain rights regarding your personal
                    information. Learn more about&nbsp;
                  </span>
                </span>
                <a data-custom-class="link" href="#privacyrights">
                  <span>
                    <span data-custom-class="body_text">
                      your privacy rights
                    </span>
                  </span>
                </a>
                <span data-custom-class="body_text">.</span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span data-custom-class="body_text">
                    <strong>How do you exercise your rights?</strong> The
                    easiest way to exercise your rights is by{" "}
                    <bdt className="block-component" />
                    visiting{" "}
                    <bdt className="question">
                    <a href="mailto:support@nyoko.com">support@nyoko.com</a>
                    </bdt>
                    <bdt className="else-block" />, or by contacting us. We will
                    consider and act upon any request in accordance with
                    applicable data protection laws.
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span data-custom-class="body_text">
                    Want to learn more about what{" "}
                    <bdt className="block-component" />
                    <bdt className="question">Nyoko</bdt>
                    <bdt className="else-block" /> does with any information we
                    collect?&nbsp;
                  </span>
                </span>
                <a data-custom-class="link" href="#toc">
                  <span>
                    <span data-custom-class="body_text">
                      Review the privacy notice in full
                    </span>
                  </span>
                </a>
                <span>
                  <span data-custom-class="body_text">.</span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div id="toc" style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span >
                      <strong>
                        <span data-custom-class="heading_1">
                          TABLE OF CONTENTS
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <a data-custom-class="link" href="#infocollect">
                    <span >
                      1. WHAT INFORMATION DO WE COLLECT?
                    </span>
                  </a>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <a data-custom-class="link" href="#infouse">
                    <span >
                      2. HOW DO WE PROCESS YOUR INFORMATION?
                      <bdt className="block-component" />
                    </span>
                  </a>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span >
                    <a data-custom-class="link" href="#whoshare">
                      3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                      INFORMATION?
                    </a>
                  </span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                  <span>
                    <span >
                      <span data-custom-class="body_text">
                        <span >
                          <bdt className="block-component" />
                        </span>
                      </span>
                      &nbsp;
                    </span>
                    &nbsp;
                  </span>
                  &nbsp;
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <a data-custom-class="link" href="#cookies">
                    <span >
                      4. DO WE USE COOKIES TRACKING TECHNOLOGIES?
                    </span>
                  </a>
                  <span>
                    <span >
                      <span data-custom-class="body_text">
                        <span >
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                      <span data-custom-class="body_text">
                        <span >
                          <span >
                            <span >
                              <bdt className="block-component" />
                            </span>
                          </span>
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <a data-custom-class="link" href="#inforetain">
                    <span >
                      5. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </span>
                  </a>
                  <span>
                    <span >
                      <span data-custom-class="body_text">
                        <span >
                          <span >
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <a data-custom-class="link" href="#infosafe">
                    <span >
                      6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </span>
                  </a>
                  <span>
                    <span >
                      <span data-custom-class="body_text">
                        <span >
                          <bdt className="statement-end-if-in-editor" />
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <a data-custom-class="link" href="#infominors">
                    <span >
                      7. DO WE COLLECT INFORMATION FROM MINORS?
                    </span>
                  </a>
                  <span>
                    <span >
                      <span data-custom-class="body_text">
                        <span >
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span >
                    <a data-custom-class="link" href="#privacyrights">
                      8. WHAT ARE YOUR PRIVACY RIGHTS?
                    </a>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <a data-custom-class="link" href="#DNT">
                    <span >
                      9. CONTROLS FOR DO-NOT-TRACK FEATURES
                    </span>
                  </a>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <a data-custom-class="link" href="#caresidents">
                    <span >
                      10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </span>
                  </a>
                </span>
                <bdt className="block-component">
                  <span>
                    <span data-custom-class="body_text" />
                  </span>
                </bdt>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <a data-custom-class="link" href="#policyupdates">
                    <span >
                      11. DO WE MAKE UPDATES TO THIS NOTICE?
                    </span>
                  </a>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <a data-custom-class="link" href="#contact">
                  <span>
                    12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </span>
                </a>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <a data-custom-class="link" href="#request">
                  <span >
                    13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                    COLLECT FROM YOU?
                  </span>
                </a>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div id="infocollect" style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span>
                      <span>
                        <span id="control" >
                          <strong>
                            <span data-custom-class="heading_1">
                              1. WHAT INFORMATION DO WE COLLECT?
                            </span>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div id="personalinfo" style={{ lineHeight: "1.5" }}>
                <span
                  data-custom-class="heading_2"
                  
                >
                  <span>
                    <strong>Personal information you disclose to us</strong>
                  </span>
                </span>
              </div>
              <div>
                <div>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <span
                        
                        >
                          <span
                          
                          >
                            <span data-custom-class="body_text">
                              <strong>
                                <em>In Short:</em>
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span data-custom-class="body_text">
                        <span
                        
                        >
                          <span
                          
                          >
                            <span data-custom-class="body_text">
                              <strong>
                                <em>&nbsp;</em>
                              </strong>
                              <em>
                                We collect personal information that you provide
                                to us.
                              </em>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      We collect personal information that you voluntarily
                      provide to us when you{" "}
                      <span>
                        <bdt className="block-component" />
                      </span>
                      register on the Services,&nbsp;
                    </span>
                    <span>
                      <span data-custom-class="body_text">
                        <span>
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                      <span data-custom-class="body_text">
                        express an interest in obtaining information about us or
                        our products and Services, when you participate in
                        activities on the Services, or otherwise when you
                        contact us.
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <span>
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <strong>Personal Information Provided by You.</strong> The
                      personal information that we collect depends on the
                      context of your interactions with us and the Services, the
                      choices you make, and the products and features you use.
                      The personal information we collect may include the
                      following:
                      <span>
                        <span data-custom-class="body_text">
                          <bdt className="forloop-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <span>
                          <span data-custom-class="body_text">
                            <bdt className="question">Names</bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <span>
                        <span data-custom-class="body_text">
                          <bdt className="forloop-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <span>
                          <span data-custom-class="body_text">
                            <bdt className="question">Phone Number</bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <span>
                        <span data-custom-class="body_text">
                          <bdt className="forloop-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <span>
                          <span data-custom-class="body_text">
                            <bdt className="question">Email address</bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <span>
                        <span data-custom-class="body_text">
                          <bdt className="forloop-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <span>
                          <span data-custom-class="body_text">
                            <bdt className="question">Job titles</bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <span>
                        <span data-custom-class="body_text">
                          <bdt className="forloop-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <span>
                          <span data-custom-class="body_text">
                            <bdt className="question">Username</bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <span>
                        <span data-custom-class="body_text">
                          <bdt className="forloop-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <span>
                          <span data-custom-class="body_text">
                            <bdt className="question">Passwords</bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <span>
                        <span data-custom-class="body_text">
                          <bdt className="forloop-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <span>
                          <span data-custom-class="body_text">
                            <bdt className="question">Identification  </bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <span>
                        <span data-custom-class="body_text">
                          <bdt className="forloop-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <span>
                          <span data-custom-class="body_text">
                            <bdt className="question">Date of Birth</bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <span>
                        <span data-custom-class="body_text">
                          <bdt className="forloop-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <span>
                          <span data-custom-class="body_text">
                            <bdt className="question">Clearance Certificate</bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <span>
                        <span data-custom-class="body_text">
                          <bdt className="forloop-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <span>
                          <span data-custom-class="body_text">
                            <bdt className="question">Address</bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <span>
                        <span data-custom-class="body_text">
                          <bdt className="forloop-component" />
                        </span>
                        <span data-custom-class="body_text">
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
                <span>
                  <span data-custom-class="body_text">
                    <strong>Sensitive Information.</strong>{" "}
                    <bdt className="block-component" />
                    We do not process sensitive information.
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span data-custom-class="body_text">
                    <bdt className="else-block" />
                  </span>
                </span>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <span>
                        <span data-custom-class="body_text">
                          <bdt className="block-component">
                            <bdt className="block-component" />
                          </bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                  <bdt className="block-component">
                    <bdt className="block-component" />
                  </bdt>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span data-custom-class="body_text">
                  <span>
                    <strong>Application Data.</strong> If you use our
                    application(s), we also may collect the following
                    information if you choose to provide us with access or
                    permission:
                    <bdt className="block-component" />
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span data-custom-class="body_text">
                    <span>
                      <em>Geolocation Information.</em> We may request access or
                      permission to track location-based information from your
                      mobile device, either continuously or while you are using
                      our mobile application(s), to provide certain
                      location-based services. If you wish to change our access
                      or permissions, you may do so in your device's settings.
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span>
                    <span data-custom-class="body_text" />
                  </span>
                </bdt>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span data-custom-class="body_text">
                      <em>Mobile Device Access.</em> We may request access or
                      permission to certain features from your mobile device,
                      including your mobile device's{" "}
                      <bdt className="forloop-component" />
                      <bdt className="question">camera</bdt>,{" "}
                      <bdt className="forloop-component" />
                      <bdt className="question">storage</bdt>,{" "}
                      <bdt className="forloop-component" />
                      <bdt className="question">calendar</bdt>,{" "}
                      <bdt className="forloop-component" />
                      <bdt className="question">contacts</bdt>,{" "}
                      <bdt className="forloop-component" />
                      and other features. If you wish to change our access or
                      permissions, you may do so in your device's settings.
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span>
                    <span data-custom-class="body_text" />
                  </span>
                </bdt>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span data-custom-class="body_text">
                      <em>Mobile Device Data.</em> We automatically collect
                      device information (such as your mobile device ID, model,
                      and manufacturer), operating system, version information
                      and system configuration information, device and
                      application identification numbers, browser type and
                      version, Internet service provider and/or
                      mobile carrier. If you are using our application(s), we may
                      also collect information about the mobile device’s
                      operating system or platform, your mobile device’s  and
                      information about the features of our application(s) you
                      accessed.
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span>
                    <span data-custom-class="body_text" />
                  </span>
                </bdt>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span data-custom-class="body_text">
                      <em>Push Notifications.</em> We may request to send you
                      push notifications regarding your account or certain
                      features of the application(s). If you wish to opt out
                      from receiving these types of communications, you may turn
                      them off in your device's settings.
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span data-custom-class="body_text">
                    This information is primarily needed to maintain the
                    security and operation of our application(s), for
                    troubleshooting, and for our internal analytics and
                    reporting purposes.
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="statement-end-if-in-editor">
                  <span>
                    <span data-custom-class="body_text" />
                  </span>
                </bdt>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      All personal information that you provide to us must be
                      true, complete, and accurate, and you must notify us of
                      any changes to such personal information.
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span
                  data-custom-class="heading_2"
                  
                >
                  <span>
                    <strong>Information automatically collected</strong>
                  </span>
                </span>
              </div>
              <div>
                <div>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <span
                        
                        >
                          <span
                          
                          >
                            <span data-custom-class="body_text">
                              <strong>
                                <em>In Short:</em>
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span data-custom-class="body_text">
                        <span
                        
                        >
                          <span
                          
                          >
                            <span data-custom-class="body_text">
                              <strong>
                                <em>&nbsp;</em>
                              </strong>
                              <em>
                                Some information — such as your Internet
                                Protocol (IP) address and/or browser and device
                                characteristics — is collected automatically
                                when you visit our Services.
                              </em>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      We automatically collect certain information when you
                      visit, use, or navigate the Services. This information
                      does not reveal your specific identity (like your name or
                      contact information) but may include device and usage
                      information, such as your IP address, browser and device
                      characteristics, operating system, language preferences,
                      referring URLs, device name, country, location,
                      information about how and when you use our Services, and
                      other technical information. This information is primarily
                      needed to maintain the security and operation of our
                      Services, and for our internal analytics and reporting
                      purposes.
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      Like many businesses, we also collect information through
                      cookies and similar technologies.{" "}
                      <bdt className="block-component" />
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="statement-end-if-in-editor">
                        <span data-custom-class="body_text" />
                      </bdt>
                    </span>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      The information we collect includes:
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <em>Log and Usage Data.</em> Log and usage data is
                        service-related, diagnostic, usage, and performance
                        information our servers automatically collect when you
                        access or use our Services and which we record in log
                        files. Depending on how you interact with us, this log
                        data may include your IP address, device information,
                        browser type, and settings and information about your
                        activity in the Services
                        <span>&nbsp;</span>(such as the
                        date/time stamps associated with your usage, pages and
                        files viewed, searches, and other actions you take such
                        as which features you use), device event information
                        (such as system activity, error reports (sometimes
                        called <bdt className="block-component" />
                        "crash dumps"
                        <bdt className="statement-end-if-in-editor" />
                        ), and hardware settings).
                        <span>
                          <span >
                            <span data-custom-class="body_text">
                              <span>
                                <span >
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span>
                    <span data-custom-class="body_text" />
                  </span>
                </bdt>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <em>Device Data.</em> We collect device data such as
                        information about your computer, phone, tablet, or other
                        device you use to access the Services. Depending on the
                        device used, this device data may include information
                        such as your IP address (or proxy server), device and
                        application identification numbers, location, browser
                        type, hardware model, Internet service provider and/or
                        mobile carrier, operating system, and system
                        configuration information.
                        <span>
                          <span >
                            <span data-custom-class="body_text">
                              <span>
                                <span >
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span>
                    <span data-custom-class="body_text" />
                  </span>
                </bdt>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <em>Location Data.</em> We collect location data such as
                        information about your device's location, which can be
                        either precise or imprecise. How much information we
                        collect depends on the type and settings of the device
                        you use to access the Services. For example, we may use
                        GPS  technologies to collect geolocation data
                        that tells us your current location (based on your IP
                        address). You can opt out of allowing us to collect this
                        information either by refusing access to the information
                        or by disabling your Location setting on your device.
                        However, if you choose to opt out, you may not be able
                        to use certain aspects of the Services.
                        <span>
                          <span >
                            <span data-custom-class="body_text">
                              <span>
                                <span >
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div>
                <bdt className="block-component">
                  <span />
                </bdt>
                <bdt className="statement-end-if-in-editor" />
                <span data-custom-class="body_text">
                  <span>
                    <span data-custom-class="body_text">
                      <span>
                        <span data-custom-class="body_text">
                          <bdt className="statement-end-if-in-editor">
                            <bdt className="block-component" />
                          </bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </div>
              <div id="infouse" style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span>
                      <span>
                        <span id="control" >
                          <strong>
                            <span data-custom-class="heading_1">
                              2. HOW DO WE PROCESS YOUR INFORMATION?
                            </span>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <span
                        
                        >
                          <span
                          
                          >
                            <span data-custom-class="body_text">
                              <strong>
                                <em>In Short:&nbsp;</em>
                              </strong>
                              <em>
                                We process your information to provide, improve,
                                and administer our Services, communicate with
                                you, for security and fraud prevention, and to
                                comply with law. We may also process your
                                information for other purposes with your
                                consent.
                              </em>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <strong>
                        We process your personal information for a variety of
                        reasons, depending on how you interact with our
                        Services, including:
                      </strong>
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <strong>
                          To facilitate account creation and authentication and
                          otherwise manage user accounts.&nbsp;
                        </strong>
                        We may process your information so you can create and
                        log in to your account, as well as keep your account in
                        working order.
                        <span
                        
                        >
                          <span
                          
                          >
                            <span data-custom-class="body_text">
                              <span>
                                <span >
                                  <span data-custom-class="body_text">
                                    <span>
                                      <span
                                        
                                      >
                                        <span data-custom-class="body_text">
                                          <bdt className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <strong>
                          To deliver and facilitate delivery of services to the
                          user.&nbsp;
                        </strong>
                        We may process your information to provide you with the
                        requested service.
                        <span
                        
                        >
                          <span
                          
                          >
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(255, 255, 255)",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(255, 255, 255)",
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <span>
                                      <span
                                        
                                      >
                                        <span data-custom-class="body_text">
                                          <span>
                                            <span
                                              style={{
                                                color: "rgb(255, 255, 255)",
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <bdt className="statement-end-if-in-editor" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
                <div style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </div>
                <ul>
                  <li style={{ lineHeight: "1.5" }}>
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          <strong>
                            To respond to user inquiries/offer support to
                            users.&nbsp;
                          </strong>
                          We may process your information to respond to your
                          inquiries and solve any potential issues you might
                          have with the requested service.
                          <span
                          
                          >
                            <span
                            
                            >
                              <span data-custom-class="body_text">
                                <span>
                                  <span >
                                    <span data-custom-class="body_text">
                                      <span>
                                        <span
                                          
                                        >
                                          <span data-custom-class="body_text">
                                            <bdt className="statement-end-if-in-editor" />
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
                <div style={{ lineHeight: "1.5" }}>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                  <div style={{ lineHeight: "1.5" }}>
                    <bdt className="block-component">
                      <span />
                    </bdt>
                    <div style={{ lineHeight: "1.5" }}>
                      <bdt className="block-component">
                        <span />
                      </bdt>
                      <div style={{ lineHeight: "1.5" }}>
                        <bdt className="block-component">
                          <span />
                        </bdt>
                      </div>
                      <ul>
                        <li style={{ lineHeight: "1.5" }}>
                          <span
                          
                          >
                            <span
                            
                            >
                              <span data-custom-class="body_text">
                                <strong>
                                  To <bdt className="block-component" />
                                  fulfill
                                  <bdt className="statement-end-if-in-editor" />{" "}
                                  and manage your orders.&nbsp;
                                </strong>
                                We may process your information to{" "}
                                <bdt className="block-component" />
                                fulfill
                                <bdt className="statement-end-if-in-editor" />{" "}
                                and manage your orders, payments, returns, and
                                exchanges made through the Services.
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(255, 255, 255)",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(255, 255, 255)",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(255, 255, 255)",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(255, 255, 255)",
                                          }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(255, 255, 255)",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(255, 255, 255)",
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <bdt className="statement-end-if-in-editor" />
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                      <div style={{ lineHeight: "1.5" }}>
                        <bdt className="block-component">
                          <span>
                            <span data-custom-class="body_text" />
                          </span>
                        </bdt>
                        <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                          <bdt className="block-component">
                            <span />
                          </bdt>
                        </p>
                        <ul>
                          <li style={{ lineHeight: "1.5" }}>
                            <span>
                              <span >
                                <span >
                                  <span data-custom-class="body_text">
                                    <strong>
                                      To enable user-to-user
                                      communications.&nbsp;
                                    </strong>
                                    We may process your information if you
                                    choose to use any of our offerings that
                                    allow for communication with another user.
                                    <span >
                                      <span
                                        
                                      >
                                        <span data-custom-class="body_text">
                                          <span
                                            
                                          >
                                            <span data-custom-class="body_text">
                                              <span
                                                style={{
                                                  color: "rgb(255, 255, 255)",
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <bdt className="statement-end-if-in-editor" />
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </li>
                        </ul>
                        <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                          <bdt className="block-component">
                            <span />
                          </bdt>
                        </p>
                        <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                          <bdt className="block-component" />
                        </p>
                        <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                          <bdt className="block-component" />
                        </p>
                        <div style={{ lineHeight: "1.5" }}>
                          <bdt className="block-component">
                            <span />
                          </bdt>
                          <div style={{ lineHeight: "1.5" }}>
                            <bdt className="block-component">
                              <span />
                            </bdt>
                            <div style={{ lineHeight: "1.5" }}>
                              <bdt className="block-component">
                                <span />
                              </bdt>
                              <div style={{ lineHeight: "1.5" }}>
                                <span>
                                  <bdt className="block-component">
                                    <span data-custom-class="body_text" />
                                  </bdt>
                                </span>
                                <div style={{ lineHeight: "1.5" }}>
                                  <bdt className="block-component">
                                    <span>
                                      <span data-custom-class="body_text" />
                                    </span>
                                  </bdt>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <bdt className="block-component">
                                      <span>
                                        <span data-custom-class="body_text" />
                                      </span>
                                    </bdt>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <bdt className="block-component">
                                        <span>
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </bdt>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                          <span>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <bdt className="block-component">
                                            <span>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span data-custom-class="body_text">
                                              <span>
                                                <strong>
                                                  To evaluate and improve our
                                                  Services, products, marketing,
                                                  and your experience.
                                                </strong>{" "}
                                                We may process your information
                                                when we believe it is necessary
                                                to identify usage trends,
                                                determine the effectiveness of
                                                our promotional campaigns, and
                                                to evaluate and improve our
                                                Services, products, marketing,
                                                and your experience.
                                              </span>
                                            </span>
                                            <bdt className="statement-end-if-in-editor">
                                              <span>
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </bdt>
                                          </li>
                                        </ul>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <bdt className="block-component">
                                            <span>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span data-custom-class="body_text">
                                              <span>
                                                <strong>
                                                  To identify usage trends.
                                                </strong>{" "}
                                                We may process information about
                                                how you use our Services to
                                                better understand how they are
                                                being used so we can improve
                                                them.
                                              </span>
                                            </span>
                                            <bdt className="statement-end-if-in-editor">
                                              <span>
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </bdt>
                                          </li>
                                        </ul>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <bdt className="block-component">
                                            <span>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <bdt className="block-component">
                                              <span>
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </bdt>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <bdt className="block-component">
                                                <span>
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </bdt>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <bdt className="block-component">
                                                  <span
                                                  
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <bdt className="block-component">
                                                    <span
                                                    
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <bdt className="block-component">
                                                      <span
                                                      
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                    <bdt className="block-component">
                                                      <span
                                                      
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                    <bdt className="block-component">
                                                      <span
                                                      
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                    <bdt className="block-component">
                                                      <span
                                                      
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="whoshare"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(255, 255, 255)",
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(255, 255, 255)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  3. WHEN AND
                                                                  WITH WHOM DO
                                                                  WE SHARE YOUR
                                                                  PERSONAL
                                                                  INFORMATION?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(255, 255, 255)",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>In Short:</em>
                                                          </strong>
                                                          <em>
                                                            &nbsp;We may share
                                                            information in
                                                            specific situations
                                                            described in this
                                                            section and/or with
                                                            the following{" "}
                                                            <bdt className="block-component" />
                                                            third parties.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(255, 255, 255)",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                    
                                                    >
                                                      <span data-custom-class="body_text">
                                                        We{" "}
                                                        <bdt className="block-component" />
                                                        may need to share your
                                                        personal information in
                                                        the following
                                                        situations:
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                      
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            Business Transfers.
                                                          </strong>{" "}
                                                          We may share or
                                                          transfer your
                                                          information in
                                                          connection with, or
                                                          during negotiations
                                                          of, any merger, sale
                                                          of company assets,
                                                          financing, or
                                                          acquisition of all or
                                                          a portion of our
                                                          business to another
                                                          company.
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                    
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                      
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            When we use Google
                                                            Maps Platform APIs.
                                                          </strong>{" "}
                                                          We may share your
                                                          information with
                                                          certain Google Maps
                                                          Platform APIs (e.g.
                                                          <bdt className="block-component" />
                                                          ,
                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                          Google Maps API,
                                                          Places API).
                                                        </span>
                                                      </span>
                                                      <span
                                                      
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />{" "}
                                                          We obtain and store on
                                                          your device (
                                                          <bdt className="block-component" />
                                                          "cache"
                                                          <bdt className="statement-end-if-in-editor" />
                                                          ) your location
                                                          <bdt className="block-component" />{" "}
                                                          for{" "}
                                                          <bdt className="question">
                                                            one (1)
                                                          </bdt>{" "}
                                                          months
                                                          <bdt className="statement-end-if-in-editor" />
                                                          . You may revoke your
                                                          consent anytime by
                                                          contacting us at the
                                                          contact details
                                                          provided at the end of
                                                          this document.
                                                          <bdt className="statement-end-if-in-editor" />
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                    
                                                    >
                                                      <bdt className="block-component">
                                                        <span data-custom-class="body_text" />
                                                      </bdt>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                      
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            Affiliates.&nbsp;
                                                          </strong>
                                                          We may share your
                                                          information with our
                                                          affiliates, in which
                                                          case we will require
                                                          those affiliates to{" "}
                                                          <bdt className="block-component" />
                                                          honor
                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                          this privacy notice.
                                                          Affiliates include our
                                                          parent company and any
                                                          subsidiaries, joint
                                                          venture partners, or
                                                          other companies that
                                                          we control or that are
                                                          under common control
                                                          with us.
                                                        </span>
                                                        <bdt className="statement-end-if-in-editor">
                                                          <span data-custom-class="body_text" />
                                                        </bdt>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <bdt className="block-component">
                                                      <span
                                                      
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <bdt className="block-component">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <ul>
                                                      <li
                                                        style={{
                                                          lineHeight: "1.5",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <strong>
                                                              Other Users.
                                                            </strong>{" "}
                                                            When you share
                                                            personal information
                                                            <bdt className="block-component" />{" "}
                                                            (for example, by
                                                            posting comments,
                                                            contributions, or
                                                            other content to the
                                                            Services)
                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                            or otherwise
                                                            interact with public
                                                            areas of the
                                                            Services, such
                                                            personal information
                                                            may be viewed by all
                                                            users and may be
                                                            publicly made
                                                            available outside
                                                            the Services in
                                                            perpetuity.
                                                            <bdt className="block-component" />{" "}
                                                            Similarly, other
                                                            users will be able
                                                            to view descriptions
                                                            of your activity,
                                                            communicate with you
                                                            within our Services,
                                                            and view your
                                                            profile.
                                                            <bdt className="statement-end-if-in-editor" />
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <bdt className="block-component">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </bdt>
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <ul>
                                                      <li
                                                        style={{
                                                          lineHeight: "1.5",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <strong>
                                                              Offer Wall.&nbsp;
                                                            </strong>
                                                            Our application(s)
                                                            may display a
                                                            third-party hosted{" "}
                                                            <bdt className="block-component" />
                                                            "offer wall."
                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                            Such an offer wall
                                                            allows third-party
                                                            advertisers to offer
                                                            virtual currency,
                                                            gifts, or other
                                                            items to users in
                                                            return for the
                                                            acceptance and
                                                            completion of an
                                                            advertisement offer.
                                                            Such an offer wall
                                                            may appear in our
                                                            application(s) and
                                                            be displayed to you
                                                            based on certain
                                                            data, such as your
                                                            geographic area or
                                                            demographic
                                                            information. When
                                                            you click on an
                                                            offer wall, you will
                                                            be brought to an
                                                            external website
                                                            belonging to other
                                                            persons and will
                                                            leave our
                                                            application(s). A
                                                            unique identifier,
                                                            such as your user
                                                            ID, will be shared
                                                            with the offer wall
                                                            provider in order to
                                                            prevent fraud and
                                                            properly credit your
                                                            account with the
                                                            relevant reward.
                                                            <bdt className="statement-end-if-in-editor" />
                                                          </span>
                                                        </span>
                                                        <bdt className="statement-end-if-in-editor">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text" />
                                                          </span>
                                                        </bdt>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <bdt className="block-component">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </bdt>
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(255, 255, 255)",
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(255, 255, 255)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <bdt className="block-component">
                                                                  <span data-custom-class="heading_1" />
                                                                </bdt>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      id="cookies"
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(127, 127, 127)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(255, 255, 255)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(255, 255, 255)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                              }}
                                                            >
                                                              <span
                                                                id="control"
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <strong>
                                                                  <span data-custom-class="heading_1">
                                                                    4. DO WE USE
                                                                    COOKIES AND
                                                                    OTHER
                                                                    TRACKING
                                                                    TECHNOLOGIES?
                                                                  </span>
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <strong>
                                                              <em>In Short:</em>
                                                            </strong>
                                                            <em>
                                                              &nbsp;We may use
                                                              cookies 
                                                              tracking
                                                              technologies to
                                                              collect and store
                                                              your information.
                                                            </em>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            We may use cookies
                                                            and similar tracking
                                                            technologies (like
                                                            web beacons and
                                                            pixels) to access or
                                                            store information.
                                                            Specific information
                                                            about how we use
                                                            such technologies
                                                            and how you can
                                                            refuse certain
                                                            cookies is set out
                                                            in our Cookie Notice
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                                .
                                                              </span>
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(255, 255, 255)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(255, 255, 255)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(255, 255, 255)",
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(255, 255, 255)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(255, 255, 255)",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                          <bdt className="block-component">
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                            </span>
                                                                          </bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      id="inforetain"
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(127, 127, 127)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(255, 255, 255)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(255, 255, 255)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                              }}
                                                            >
                                                              <span
                                                                id="control"
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <strong>
                                                                  <span data-custom-class="heading_1">
                                                                    5. HOW LONG
                                                                    DO WE KEEP
                                                                    YOUR
                                                                    INFORMATION?
                                                                  </span>
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <strong>
                                                              <em>
                                                                In Short:&nbsp;
                                                              </em>
                                                            </strong>
                                                            <em>
                                                              We keep your
                                                              information for as
                                                              long as necessary
                                                              to{" "}
                                                              <bdt className="block-component" />
                                                              fulfill
                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                              the purposes
                                                              outlined in this
                                                              privacy notice
                                                              unless otherwise
                                                              required by law.
                                                            </em>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            We will only keep
                                                            your personal
                                                            information for as
                                                            long as it is
                                                            necessary for the
                                                            purposes set out in
                                                            this privacy notice,
                                                            unless a longer
                                                            retention period is
                                                            required or
                                                            permitted by law
                                                            (such as tax,
                                                            accounting, or other
                                                            legal requirements).
                                                            <bdt className="block-component" />{" "}
                                                            No purpose in this
                                                            notice will require
                                                            us keeping your
                                                            personal information
                                                            for longer than{" "}
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <bdt className="block-component" />
                                                            <bdt className="question">
                                                              two (2)
                                                            </bdt>{" "}
                                                            months past the
                                                            termination of the
                                                            user's account
                                                            <bdt className="block-component" />
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="else-block" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                            .
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            When we have no
                                                            ongoing legitimate
                                                            business need to
                                                            process your
                                                            personal
                                                            information, we will
                                                            either delete or{" "}
                                                            <bdt className="block-component" />
                                                            anonymize
                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                            such information,
                                                            or, if this is not
                                                            possible (for
                                                            example, because
                                                            your personal
                                                            information has been
                                                            stored in backup
                                                            archives), then we
                                                            will securely store
                                                            your personal
                                                            information and
                                                            isolate it from any
                                                            further processing
                                                            until deletion is
                                                            possible.
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                              }}
                                                            >
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      id="infosafe"
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(127, 127, 127)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(255, 255, 255)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(255, 255, 255)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                              }}
                                                            >
                                                              <span
                                                                id="control"
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <strong>
                                                                  <span data-custom-class="heading_1">
                                                                    6. HOW DO WE
                                                                    KEEP YOUR
                                                                    INFORMATION
                                                                    SAFE?
                                                                  </span>
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <strong>
                                                              <em>
                                                                In Short:&nbsp;
                                                              </em>
                                                            </strong>
                                                            <em>
                                                              We aim to protect
                                                              your personal
                                                              information
                                                              through a system
                                                              of{" "}
                                                              <bdt className="block-component" />
                                                              organizational
                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                              and technical
                                                              security measures.
                                                            </em>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            We have implemented
                                                            appropriate and
                                                            reasonable technical
                                                            and{" "}
                                                            <bdt className="block-component" />
                                                            organizational
                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                            security measures
                                                            designed to protect
                                                            the security of any
                                                            personal information
                                                            we process. However,
                                                            despite our
                                                            safeguards and
                                                            efforts to secure
                                                            your information, no
                                                            electronic
                                                            transmission over
                                                            the Internet or
                                                            information storage
                                                            technology can be
                                                            guaranteed to be
                                                            100% secure, so we
                                                            cannot promise or
                                                            guarantee that
                                                            hackers,
                                                            cybercriminals, or
                                                            other{" "}
                                                            <bdt className="block-component" />
                                                            unauthorized
                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                            third parties will
                                                            not be able to
                                                            defeat our security
                                                            and improperly
                                                            collect, access,
                                                            steal, or modify
                                                            your information.
                                                            Although we will do
                                                            our best to protect
                                                            your personal
                                                            information,
                                                            transmission of
                                                            personal information
                                                            to and from our
                                                            Services is at your
                                                            own risk. You should
                                                            only access the
                                                            Services within a
                                                            secure environment.
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                              }}
                                                            >
                                                              <bdt className="statement-end-if-in-editor" />
                                                            </span>
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      id="infominors"
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(127, 127, 127)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(255, 255, 255)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(255, 255, 255)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                              }}
                                                            >
                                                              <span
                                                                id="control"
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <strong>
                                                                  <span data-custom-class="heading_1">
                                                                    7. DO WE
                                                                    COLLECT
                                                                    INFORMATION
                                                                    FROM MINORS?
                                                                  </span>
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <strong>
                                                              <em>In Short:</em>
                                                            </strong>
                                                            <em>
                                                              &nbsp;We do not
                                                              knowingly collect
                                                              data from or
                                                              market to{" "}
                                                              <bdt className="block-component" />
                                                              children under 18
                                                              years of age
                                                              <bdt className="else-block" />
                                                              .
                                                            </em>
                                                            <bdt className="block-component" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            We do not knowingly
                                                            solicit data from or
                                                            market to children
                                                            under 18 years of
                                                            age. By using the
                                                            Services, you
                                                            represent that you
                                                            are at least 18.
                                                            If we learn that
                                                            personal information
                                                            from users less than
                                                            18 years of age has
                                                            been collected, we
                                                            will deactivate the
                                                            account and take
                                                            reasonable measures
                                                            to promptly delete
                                                            such data from our
                                                            records. If you
                                                            become aware of any
                                                            data we may have
                                                            collected from
                                                            children under age
                                                            18, please contact
                                                            us at{" "}
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                                <bdt className="question">
                                                                  support@nyoko.com
                                                                </bdt>
                                                                <bdt className="else-block" />
                                                              </span>
                                                            </span>
                                                            .
                                                          </span>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="else-block" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      id="privacyrights"
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(127, 127, 127)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(255, 255, 255)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(255, 255, 255)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                              }}
                                                            >
                                                              <span
                                                                id="control"
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <strong>
                                                                  <span data-custom-class="heading_1">
                                                                    8. WHAT ARE
                                                                    YOUR PRIVACY
                                                                    RIGHTS?
                                                                  </span>
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <strong>
                                                              <em>In Short:</em>
                                                            </strong>
                                                            <em>
                                                              &nbsp;
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <em>
                                                                      <bdt className="block-component" />
                                                                    </em>
                                                                  </span>
                                                                </span>
                                                                &nbsp;
                                                              </span>
                                                              You may review,
                                                              change, or
                                                              terminate your
                                                              account at any
                                                              time.
                                                            </em>
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        &nbsp;
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            If you are located
                                                            in the EEA or UK and
                                                            you believe we are
                                                            unlawfully
                                                            processing your
                                                            personal
                                                            information, you
                                                            also have the right
                                                            to complain to your{" "}
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(48, 48, 241)",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <a
                                                                        data-custom-class="link"
                                                                        href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          Member
                                                                          State
                                                                          data
                                                                          protection
                                                                          authority
                                                                        </span>
                                                                      </a>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>{" "}
                                                            or&nbsp;
                                                          </span>
                                                        </span>
                                                      </span>
                                                      <a
                                                        href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                      >
                                                        <span data-custom-class="link">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(255, 255, 255)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                UK data
                                                                protection
                                                                authority
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </a>
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            .
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            If you are located
                                                            in Switzerland, you
                                                            may contact the{" "}
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(48, 48, 241)",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <a
                                                                          data-custom-class="link"
                                                                          href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                          rel="noopener noreferrer"
                                                                          target="_blank"
                                                                        >
                                                                          Federal
                                                                          Data
                                                                          Protection
                                                                          and
                                                                          Information
                                                                          Commissioner
                                                                        </a>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                            .
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      id="withdrawconsent"
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <strong>
                                                              <u>
                                                                Withdrawing your
                                                                consent:
                                                              </u>
                                                            </strong>{" "}
                                                            If we are relying on
                                                            your consent to
                                                            process your
                                                            personal
                                                            information,
                                                            <bdt className="block-component" />{" "}
                                                            which may be express
                                                            and/or implied
                                                            consent depending on
                                                            the applicable law,
                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                            you have the right
                                                            to withdraw your
                                                            consent at any time.
                                                            You can withdraw
                                                            your consent at any
                                                            time by contacting
                                                            us by using the
                                                            contact details
                                                            provided in the
                                                            section{" "}
                                                            <bdt className="block-component" />
                                                            "
                                                            <bdt className="statement-end-if-in-editor" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                      <a
                                                        data-custom-class="link"
                                                        href="#contact"
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(255, 255, 255)",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              HOW CAN YOU
                                                              CONTACT US ABOUT
                                                              THIS NOTICE?
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </a>
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                            "
                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                            below
                                                            <bdt className="block-component" />
                                                            .
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                      
                                                      >
                                                        <span data-custom-class="body_text">
                                                          However, please note
                                                          that this will not
                                                          affect the lawfulness
                                                          of the processing
                                                          before its withdrawal
                                                          nor,
                                                          <bdt className="block-component" />{" "}
                                                          when applicable law
                                                          allows,
                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                          will it affect the
                                                          processing of your
                                                          personal information
                                                          conducted in reliance
                                                          on lawful processing
                                                          grounds other than
                                                          consent.
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                      
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <u>
                                                              Opting out of
                                                              marketing and
                                                              promotional
                                                              communications:
                                                            </u>
                                                          </strong>
                                                          <strong>
                                                            <u>&nbsp;</u>
                                                          </strong>
                                                          You can unsubscribe
                                                          from our marketing and
                                                          promotional
                                                          communications at any
                                                          time by
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />{" "}
                                                          replying{" "}
                                                          <bdt className="block-component" />
                                                          "STOP" or
                                                          "UNSUBSCRIBE"
                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                          to the SMS messages
                                                          that we send,
                                                          <bdt className="statement-end-if-in-editor" />
                                                          <bdt className="block-component" />{" "}
                                                          or by contacting us
                                                          using the details
                                                          provided in the
                                                          section{" "}
                                                          <bdt className="block-component" />
                                                          "
                                                          <bdt className="statement-end-if-in-editor" />
                                                        </span>
                                                      </span>
                                                      <a
                                                        data-custom-class="link"
                                                        href="#contact"
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            HOW CAN YOU CONTACT
                                                            US ABOUT THIS
                                                            NOTICE?
                                                          </span>
                                                        </span>
                                                      </a>
                                                      <span
                                                      
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component" />
                                                          "
                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                          below. You will then
                                                          be removed from the
                                                          marketing lists.
                                                          However, we may still
                                                          communicate with you —
                                                          for example, to send
                                                          you service-related
                                                          messages that are
                                                          necessary for the
                                                          administration and use
                                                          of your account, to
                                                          respond to service
                                                          requests, or for other
                                                          non-marketing
                                                          purposes.
                                                        </span>
                                                        <span data-custom-class="body_text">
                                                          <bdt className="statement-end-if-in-editor" />
                                                        </span>
                                                      </span>
                                                      <bdt className="block-component">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                      
                                                      >
                                                        <span data-custom-class="heading_2">
                                                          <strong>
                                                            Account Information
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          If you would at any
                                                          time like to review or
                                                          change the information
                                                          in your account or
                                                          terminate your
                                                          account, you can:
                                                          <bdt className="forloop-component" />
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <ul>
                                                      <li
                                                        style={{
                                                          lineHeight: "1.5",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <bdt className="question">
                                                              Log in to your
                                                              account settings
                                                              and update your
                                                              user account.
                                                            </bdt>
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <bdt className="forloop-component" />
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <ul>
                                                      <li
                                                        style={{
                                                          lineHeight: "1.5",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <bdt className="question">
                                                              Contact us using
                                                              the contact
                                                              information
                                                              provided.
                                                            </bdt>
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <bdt className="forloop-component" />
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                      
                                                      >
                                                        <span data-custom-class="body_text">
                                                          You have the option to delete your account on your own terms by visiting the settings section of the app. Upon your request to delete your account, your account and information from our active databases will be removed.
                                                          However, we may retain
                                                          some information in
                                                          our files to prevent
                                                          fraud, troubleshoot
                                                          problems, assist with
                                                          any investigations,
                                                          enforce our legal
                                                          terms and/or comply
                                                          with applicable legal
                                                          requirements.
                                                        </span>
                                                      </span>
                                                      <bdt className="statement-end-if-in-editor">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </bdt>
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(255, 255, 255)",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(255, 255, 255)",
                                                                          }}
                                                                        >
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <strong>
                                                              <u>
                                                                Cookies and
                                                                similar
                                                                technologies:
                                                              </u>
                                                            </strong>{" "}
                                                            Most Web browsers
                                                            are set to accept
                                                            cookies by default.
                                                            If you prefer, you
                                                            can usually choose
                                                            to set your browser
                                                            to remove cookies
                                                            and to reject
                                                            cookies. If you
                                                            choose to remove
                                                            cookies or reject
                                                            cookies, this could
                                                            affect certain
                                                            features or services
                                                            of our Services. You
                                                            may also{" "}
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(48, 48, 241)",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <a
                                                                  data-custom-class="link"
                                                                  href="http://www.aboutads.info/choices/"
                                                                  rel="noopener noreferrer"
                                                                  target="_blank"
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    opt out of
                                                                    interest-based
                                                                    advertising
                                                                    by
                                                                    advertisers
                                                                  </span>
                                                                </a>
                                                              </span>
                                                            </span>{" "}
                                                            on our Services.{" "}
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component" />
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(255, 255, 255)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(255, 255, 255)",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(255, 255, 255)",
                                                                              }}
                                                                            >
                                                                              <bdt className="statement-end-if-in-editor" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                      <bdt className="block-component">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          If you have questions
                                                          or comments about your
                                                          privacy rights, you
                                                          may email us at{" "}
                                                          <bdt className="question">
                                                            support@nyoko.com
                                                          </bdt>
                                                          .
                                                        </span>
                                                      </span>
                                                      <bdt className="statement-end-if-in-editor">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      id="DNT"
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(127, 127, 127)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(255, 255, 255)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(255, 255, 255)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                              }}
                                                            >
                                                              <span
                                                                id="control"
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <strong>
                                                                  <span data-custom-class="heading_1">
                                                                    9. CONTROLS
                                                                    FOR
                                                                    DO-NOT-TRACK
                                                                    FEATURES
                                                                  </span>
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            Most web browsers
                                                            and some mobile
                                                            operating systems
                                                            and mobile
                                                            applications include
                                                            a Do-Not-Track (
                                                            <bdt className="block-component" />
                                                            "DNT"
                                                            <bdt className="statement-end-if-in-editor" />
                                                            ) feature or setting
                                                            you can activate to
                                                            signal your privacy
                                                            preference not to
                                                            have data about your
                                                            online browsing
                                                            activities monitored
                                                            and collected. At
                                                            this stage no
                                                            uniform technology
                                                            standard for{" "}
                                                            <bdt className="block-component" />
                                                            recognizing
                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                            and implementing DNT
                                                            signals has been{" "}
                                                            <bdt className="block-component" />
                                                            finalized
                                                            <bdt className="statement-end-if-in-editor" />
                                                            . As such, we do not
                                                            currently respond to
                                                            DNT browser signals
                                                            or any other
                                                            mechanism that
                                                            automatically
                                                            communicates your
                                                            choice not to be
                                                            tracked online. If a
                                                            standard for online
                                                            tracking is adopted
                                                            that we must follow
                                                            in the future, we
                                                            will inform you
                                                            about that practice
                                                            in a revised version
                                                            of this privacy
                                                            notice.
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      id="caresidents"
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(127, 127, 127)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(255, 255, 255)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(255, 255, 255)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                              }}
                                                            >
                                                              <span
                                                                id="control"
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <strong>
                                                                  <span data-custom-class="heading_1">
                                                                    10. DO
                                                                    CALIFORNIA
                                                                    RESIDENTS
                                                                    HAVE
                                                                    SPECIFIC
                                                                    PRIVACY
                                                                    RIGHTS?
                                                                  </span>
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <strong>
                                                              <em>
                                                                In Short:&nbsp;
                                                              </em>
                                                            </strong>
                                                            <em>
                                                              Yes, if you are a
                                                              resident of
                                                              California, you
                                                              are granted
                                                              specific rights
                                                              regarding access
                                                              to your personal
                                                              information.
                                                            </em>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            California Civil
                                                            Code Section
                                                            1798.83, also known
                                                            as the{" "}
                                                            <bdt className="block-component" />
                                                            "Shine The Light"
                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                            law, permits our
                                                            users who are
                                                            California residents
                                                            to request and
                                                            obtain from us, once
                                                            a year and free of
                                                            charge, information
                                                            about categories of
                                                            personal information
                                                            (if any) we
                                                            disclosed to third
                                                            parties for direct
                                                            marketing purposes
                                                            and the names and
                                                            addresses of all
                                                            third parties with
                                                            which we shared
                                                            personal information
                                                            in the immediately
                                                            preceding calendar
                                                            year. If you are a
                                                            California resident
                                                            and would like to
                                                            make such a request,
                                                            please submit your
                                                            request in writing
                                                            to us using the
                                                            contact information
                                                            provided below.
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            If you are under 18
                                                            years of age, reside
                                                            in California, and
                                                            have a registered
                                                            account with
                                                            Services, you have
                                                            the right to request
                                                            removal of unwanted
                                                            data that you
                                                            publicly post on the
                                                            Services. To request
                                                            removal of such
                                                            data, please contact
                                                            us using the contact
                                                            information provided
                                                            below and include
                                                            the email address
                                                            associated with your
                                                            account and a
                                                            statement that you
                                                            reside in
                                                            California. We will
                                                            make sure the data
                                                            is not publicly
                                                            displayed on the
                                                            Services, but please
                                                            be aware that the
                                                            data may not be
                                                            completely or
                                                            comprehensively
                                                            removed from all our
                                                            systems (e.g.
                                                            <bdt className="block-component" />
                                                            ,
                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                            backups, etc.).
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                      <bdt className="block-component">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        />
                                                      </bdt>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      id="policyupdates"
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(127, 127, 127)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(255, 255, 255)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(255, 255, 255)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                              }}
                                                            >
                                                              <span
                                                                id="control"
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <strong>
                                                                  <span data-custom-class="heading_1">
                                                                    11. DO WE
                                                                    MAKE UPDATES
                                                                    TO THIS
                                                                    NOTICE?
                                                                  </span>
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <em>
                                                              <strong>
                                                                In Short:&nbsp;
                                                              </strong>
                                                              Yes, we will
                                                              update this notice
                                                              as necessary to
                                                              stay compliant
                                                              with relevant
                                                              laws.
                                                            </em>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            We may update this
                                                            privacy notice from
                                                            time to time. The
                                                            updated version will
                                                            be indicated by an
                                                            updated{" "}
                                                            <bdt className="block-component" />
                                                            "Revised"
                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                            date and the updated
                                                            version will be
                                                            effective as soon as
                                                            it is accessible. If
                                                            we make material
                                                            changes to this
                                                            privacy notice, we
                                                            may notify you
                                                            either by
                                                            prominently posting
                                                            a notice of such
                                                            changes or by
                                                            directly sending you
                                                            a notification. We
                                                            encourage you to
                                                            review this privacy
                                                            notice frequently to
                                                            be informed of how
                                                            we are protecting
                                                            your information.
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      id="contact"
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(127, 127, 127)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(255, 255, 255)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(255, 255, 255)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                              }}
                                                            >
                                                              <span
                                                                id="control"
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <strong>
                                                                  <span data-custom-class="heading_1">
                                                                    12. HOW CAN
                                                                    YOU CONTACT
                                                                    US ABOUT
                                                                    THIS NOTICE?
                                                                  </span>
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            If you have
                                                            questions or
                                                            comments about this
                                                            notice, you may{" "}
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                                email us at{" "}
                                                                <bdt className="question">
                                                                  support@nyoko.com
                                                                </bdt>
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  &nbsp;or by
                                                                  post to:
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(255, 255, 255)",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="question">
                                                                      Nyoko Inc
                                                                    </bdt>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                      
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <bdt className="question">
                                                            Doha, Qatar
                                                          </bdt>
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(255, 255, 255)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                      
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <bdt className="question">
                                                            Doha
                                                          </bdt>
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(255, 255, 255)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <bdt className="block-component" />
                                                              ,{" "}
                                                              <bdt className="question">
                                                                Qatar
                                                              </bdt>
                                                              <bdt className="statement-end-if-in-editor" />
                                                              <bdt className="block-component" />
                                                              <bdt className="block-component" />
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                      
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                          <bdt className="question">
                                                            Qatar
                                                          </bdt>
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <bdt className="statement-end-if-in-editor">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(255, 255, 255)",
                                                                        }}
                                                                      >
                                                                        <bdt className="statement-end-if-in-editor">
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(255, 255, 255)",
                                                                                }}
                                                                              >
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </bdt>
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(255, 255, 255)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </bdt>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <bdt className="statement-end-if-in-editor">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(255, 255, 255)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component">
                                                                          <bdt className="block-component" />
                                                                        </bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </bdt>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="statement-end-if-in-editor">
                                                                      <bdt className="block-component" />
                                                                    </bdt>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      id="request"
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(127, 127, 127)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(255, 255, 255)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(255, 255, 255)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(255, 255, 255)",
                                                              }}
                                                            >
                                                              <span
                                                                id="control"
                                                                style={{
                                                                  color:
                                                                    "rgb(255, 255, 255)",
                                                                }}
                                                              >
                                                                <strong>
                                                                  <span data-custom-class="heading_1">
                                                                    13. HOW CAN
                                                                    YOU REVIEW,
                                                                    UPDATE, OR
                                                                    DELETE THE
                                                                    DATA WE
                                                                    COLLECT FROM
                                                                    YOU?
                                                                  </span>
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        
                                                      >
                                                        <span
                                                         
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                            Based on the
                                                            applicable laws of
                                                            your country, you
                                                            may have the right
                                                            to request access to
                                                            the personal
                                                            information we
                                                            collect from you,
                                                            change that
                                                            information, or
                                                            delete it.{" "}
                                                            <bdt className="else-block" />
                                             To do so, you can visit the settings section of the app where you'll find options to delete your account either temporarily or permanently.{" "} If you have any questions or need further assistance, you can also contact us at
                                                            <bdt className="block-component" />
                                                          </span>
                                                          <span data-custom-class="body_text">
                                                            {" "}
                                                            <bdt className="question">
                                                             <a href="mailto:support@nyoko.com">support@nyoko.com</a>
                                                            </bdt>
                                                            <bdt className="else-block" />
                                                          </span>
                                                        </span>
                                                        <span data-custom-class="body_text">
                                                          .
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <style
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          "\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    ",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </>
    </div>
  );
}

export default PrivacyPolicy;
