import { useState } from "react";

export default function BasicTextFields({
  value,
  label,
  type,
  onChange,
  error,
  helperText,
  height = "100px",
  pattern,
}) {
  const [hasContent, setHasContent] = useState(false);

  const handleFocus = () => {
    setHasContent(true);
  };

  const handleBlur = (event) => {
    if (event.target.value === "") {
      setHasContent(false);
    }
  };
  return (
    <div>
      <input
        type={type || "text"}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        pattern={pattern}
        onBlur={handleBlur}
        className={`border-0 w-full h-${height} p-3 rounded-lg focus:outline-none bg-[#201F1F] text-[#B3B4B3] placeholder:text-[#B3B4B3] ${
          hasContent ? "border-[#FFD700]" : ""
        }`}
        style={{
          "::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
        }}
        placeholder={label}
      />
      {error && <p className="text-red-500">{helperText}</p>}
    </div>
  );
}
