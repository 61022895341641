export default function GifLoading({ path }) {
  return (
    <div className="w-100 h-100 flex flex-col justify-center items-center">
      <img
        src={path ?? "/main.gif"}
        alt="Demo of the Nyoko"
        className="gif-image"
        style={{ height: "100vh" }}
      />
    </div>
  );
}
