import { IconButton, Stack } from "@mui/material";

import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
export default function SocialMediaBar({ isMobile }) {
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        display: "flex",
        justifyContent: isMobile ? "center" : "start",
        alignItems: "start",
        gap: isMobile ? "10px" : "43px",
      }}
    >
      <IconButton
        aria-label="Instagram"
        href="https://www.instagram.com/nyokoinc/"
        target="_blank"
        rel="noopener"
        sx={{
          border: "2px solid #C4C4C4",
          width: isMobile ? "50px" : "70px",
          height: isMobile ? "50px" : "70px",
          margin: "0px",
        }}
      >
        <InstagramIcon
          sx={{
            color: "#C4C4C4",
            margin: "0px",
            width: isMobile ? "20px" : "35px",
            height: isMobile ? "20px" : "35px",
          }}
        />
      </IconButton>
      <IconButton
        aria-hidden="false"
        color="white"
        width="100px"
        aria-label="LinkedIn"
        href="https://www.linkedin.com/company/nyoko-inc/"
        target="_blank"
        rel="noopener"
        sx={{
          border: "2px solid #C4C4C4",
          width: isMobile ? "50px" : "70px",
          height: isMobile ? "50px" : "70px",
          margin: "0px",
        }}
      >
        <LinkedInIcon
          sx={{
            color: "#C4C4C4",
            margin: "0px",
            width: isMobile ? "20px" : "35px",
            height: isMobile ? "20px" : "35px",
          }}
        />
      </IconButton>
      <IconButton
        aria-label="Facebook"
        href="mailto:contact@nyoko.com"
        target="_blank"
        rel="noopener"
        sx={{
          border: "2px solid #C4C4C4",
          width: isMobile ? "50px" : "70px",
          height: isMobile ? "50px" : "70px",
        }}
      >
        <EmailIcon
          sx={{
            color: "#C4C4C4",
            margin: "0px",
            width: isMobile ? "20px" : "35px",
            height: isMobile ? "20px" : "35px",
          }}
        />
      </IconButton>
    </Stack>
  );
}
