import React from 'react';
import NavbarNyoko from '../Components/navbar';
import SecondSec from '../Components/secondSec';
import ExplorePhotographers from '../Components/text';
import Grid from '@mui/material/Grid'; // Correct import for MUI v5+
import thankyou from '../thankyou.png'
import ContainedButtons from '../Components/button';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import BottomAppBar from '../Components/bottombar';


function Thankyou() {
  const theme = useTheme();
  // This will be true if the screen width is less than 'sm' breakpoint
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div style={{
        padding: '0',
        overflowX: 'hidden', 
        backgroundColor: '#201F1F',
        color: 'white',
        height: '100vh',
        fontFamily: 'lato,sans-serif',
        width: '100vw', 
      }}
    >
    <div style={{ display: 'flex', flexDirection: 'column' }}>
  {/* Conditionally render the NavbarNyoko based on screen size */}
  {isMobile && (
    <div>
      <NavbarNyoko marginleft='29px'/>
    </div>
  )}

  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
    {/* Left Section */}
    <div style={{ flex: 1, display: isMobile ? 'none' : 'flex' }}>
      <SecondSec widthimg='100%' imgSrc={thankyou} altText="Banner" includeMargin={true} margintop='30px' height='90vh' />
    </div>

    {/* Right Section */}
    <div style={{ flex: 1 }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ flex: 1, marginLeft: isMobile ? '10px' : '0' }}>
          <ExplorePhotographers parawidth='320px' title={'Thank you.'} paragraph={"You have been added to our waiting list. We will inform you once we are ready to launch."} marginleft={isMobile ? '12px' : '0px'} marginright={isMobile ? '0px' : '100px'} headingfont='59px' paragraphfont={isMobile ? '16px' : '20px'} />
        </div>
        <div style={{ flex: 1, marginLeft: isMobile ? '10px' : '0' }}>
          <ContainedButtons formSubmit={false} includeMargin={isMobile ? '20px' : '0px'} navigateTo={'/'} bgcolor='#253A40' outline='#2F96A9' textcolor='#EEEEEE' text='Done' alignment={isMobile ? 'left' : 'left'} width={isMobile ? '86%' : '70%'} />
        </div>
      </div>
    </div>
  </div>
</div>



      {/* <BottomAppBar  alignment='center'></BottomAppBar> */}

    </div>
  );
}

export default Thankyou;
