import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';

function ExplorePhotographers({ title, paragraph,marginright,marginleft,headingfont,paragraphfont, parawidth }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const titleStyle = {
    fontSize: isMobile ? '28px': headingfont,
    fontWeight: 600,
    fontFamily: isMobile?'Montserrat, sans-serif':'Montserrat, sans-serif',
    letterSpacing: '2px',
    textAlign: 'left',
    // marginLeft: marginLeftValue,
    marginLeft: isMobile ? '20px': marginleft,
    maxWidth: isMobile ? '320px' : '75%', // Adjust maxWidth for mobile
    color:'#EEEEEE',
  };

  const paragraphStyle = {
    fontFamily: 'lato,sans-serif',
    color: '#B3B4B3', // Assuming white text color
    fontSize: paragraphfont, // Example paragraph size // Align with the title
    textAlign: 'left',
    maxWidth: isMobile ? parawidth : '70%', // Adjust maxWidth for mobile
    // marginLeft: marginLeftValue,
    marginLeft: isMobile ? '20px': marginleft,
    lineHeight: 1.5,
    marginRight:marginright,
  };

  return (
    <div>
      <h1 style={titleStyle}>
        {title}
      </h1>
      <p style={paragraphStyle}>
        {paragraph}
      </p>
    </div>
  );
}

export default ExplorePhotographers;
