import React from 'react';

const Subtitle = ({ text }) => {
    return (
        <div className='text-[#676767] font-sans'>
            <p >{text}</p>
        </div>
    );
};

export default Subtitle;