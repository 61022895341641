import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { borders } from '@mui/system';

export default function ContainedButtons({ includeMargin,navigateTo, bgcolor, text, textcolor, width, alignment, formSubmit ,outline}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // This function will navigate to the route specified by navigateTo prop when called
  const handleNavigation = async () =>  {

    if (formSubmit) {
      const submitSuccess = await formSubmit();
      // If submission is not successful, return early to prevent navigation
      if (!submitSuccess) return;
    }
    navigate(navigateTo);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
       justifyContent: alignment,
       
      }}
    >
      <Button
        variant="outlined"
        sx={{
          width: width, // Full width on mobile for better responsiveness
          height: isMobile? '6vh':'8vh',
          borderColor:outline,
          backgroundColor: bgcolor,
          color: textcolor,
          '&:hover': {
            backgroundColor: bgcolor,
            borderColor:outline,
          },
          // borderColor: outline, // use borderColor for setting the border color
          textTransform: 'none',
          fontFamily: 'lato,sans-serif',
          fontSize: isMobile ? '16px' : '1.50vw', // Smaller font size on mobile
          marginLeft: includeMargin,
        }}
        onClick={handleNavigation}
      >
        {text}
      </Button>
    </Stack>
  );
}
