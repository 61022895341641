import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useState } from 'react';



export default function EmailInput({value,onChange,error,helperText}) {
  const theme = useTheme();
  // This will be true if the screen width is less than 'sm' breakpoint
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [hasContent, setHasContent] = useState(false);

  const handleFocus = () => {
    setHasContent(true);
  };

  const handleBlur = (event) => {
    if (event.target.value === '') {
      setHasContent(false);
    }
  };
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: isMobile ? '88%' : '70%', // 100% width on mobile
        marginLeft: isMobile ? '20px':'0px' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField 
        
        id="email-input"
        label={!hasContent ? 'Email Address' : ''}
        onFocus={handleFocus}
        onBlur={handleBlur}
        type="email" // Set the type to email
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText} 
        
        // Add custom styles for the filled TextField
        InputProps={{
          sx: {
            borderRadius:'7px',
            outline:'none',
            height: '52px',
            color:'white',
            bgcolor: '#272727', // Change the background color
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent', // Remove border on hover
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent', // Remove border on focus
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent', // Remove default border
            },
            '&:hover': {
              bgcolor: '#272727' , // Maintain the bgcolor on hover
            },
            '&.Mui-focused': {
              bgcolor: '#272727', // Maintain the bgcolor when focused
            },
            '&.MuiFilledInput-underline:after': {
              borderBottomColor: '#272727', // Change underline color when focused
            },
            input: {
              marginLeft: '10px', // Match this with the marginLeft of the label
            },
          }
        }}
        // Add custom styles for the label of the TextField
        InputLabelProps={{
          sx: {
            marginLeft:'10px',
            color: '#B3B4B3', // Change the color of the label text
            "&.Mui-focused": {
              color: '#272727', // Change the color when the input is focused
            }
          }
        }}
      />
    </Box>
  );
}
