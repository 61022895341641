import React from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const TitleHeading = ({ title, fontSize }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const dynamicFontSize = isMobile
    ? "5vw"
    : isTablet
    ? "3.5vw"
    : fontSize || "3vw";

  return (
    <div
      className={`text-white font-[montserrat] w-fit font-bold`}
      style={{
        fontSize: dynamicFontSize,
      }}
    >
      <h1 style={{ letterSpacing: "4px" }}>{title}</h1>
    </div>
  );
};

export default TitleHeading;
